<template>
  <div class="w-100 card-filters-search-by">
    <div class="d-flex w-100 align-items-center h-100 flex-wrap flex-md-nowrap">
      <component 
        label="text"
        class="select-filters-searcher-by"
        id="select-filters-searcher-by-id"
        :is="vue_select" 
        :options="select_filters" 
        v-model="selected_filter"
        :clearable="false"
        v-if="!no_categories"
      ></component>
      <component 
        label="text"
        class="select-filters-searcher-by"
        id="select-filters-searcher-by-id"
        :is="vue_select" 
        :options="select_keywords" 
        v-model="selected_keywords"
        :clearable="false"
        v-if="selected_filter.value === 'keywords'"
      ></component>

      <div class="position-relative w-100 h-100">
        <b-input-group class="h-100 bg-white-custom">
          <template #append v-if="selected_filter.value === 'category'">
            <div class="avenir-medium h-100 d-flex align-items-center mr-1">
              <span class="text-how-it-works" @click="howItWorks">
                <span class="d-none d-sm-flex"> {{ $t('filters.howItWorks') }}</span>
                <feather-icon icon="InfoIcon" class="ml-05"></feather-icon>
              </span>
            </div>
          </template>
          <template #append v-else-if="selected_filter.value === 'keywords' && keywords.length > 0">
            <div class="h-100 d-flex align-items-center mr-1">
              <span class="text-how-it-works text-blue" @click.prevent="handleAddTagBegin">
                <span class="d-none d-sm-flex"> {{ $t('search.add_keyword') }}</span>
              </span>
            </div>
          </template>
          <b-form-input 
            class="input-search-by-influencer h-100" 
            id="input-search-by-influencer"
            :placeholder="$t('search.placerholder_search_content')"
            v-model="keywords"
            autocomplete="off"
            @keyup.enter="handleKeyup"
            @focus="handleFocus"
            @blur="handleBlur"
            ref="input_search_by_influencer"
            :readonly="get_disabled_custom_category"
          ></b-form-input>
        </b-input-group>
        
        <div class="float-suggestions-search" v-if="show_category">
          <div v-for="(array, index) in keywords.length > 0 ? filtered_categories : get_arrays_grouped" :key="index" :class="{'mb-4': index === 'G', 'mb-2': keywords.length === 0}">
            <div v-if="keywords.length === 0">
              <strong class="m-06">{{index}}</strong>
              <p v-for="(word, second_index) in array" :key="second_index" class="avenir-medium m-05"><span @click="keywords = word" class="text-category p-05">{{word}}</span></p>
            </div>
            <div v-else >
              <p class="avenir-medium m-05"><span @click="keywords = array.title" class="text-category p-05">{{array.title}}</span></p>
            </div>
          </div>
          <p v-if="keywords.length > 0" class="avenir-medium m-05"><span class="text-category p-05">{{ $t('search.searchBy') }} <strong>{{keywords}}</strong></span></p>
        </div>

        <div class="float-suggestions-keywords" v-if="show_keywords_added">
          <div v-if="keywords_array.length > 0" class="d-flex flex-wrap p-07">
            <div v-for="i in keywords_array" :key="i.value" class="tag">
              <span v-if="i.type_keyword !== 'anywhere'" class="avenir-medium">{{ select_keywords.find(j => j.value === i.type_keyword).text }}: </span>
                {{ i.keyword }}
              <feather-icon icon="XIcon" class="cursor-pointer" @click="deleteTag(i)"></feather-icon>  
            </div>
          </div>
          <div v-else>
            <div class="title-recent-word">{{ $t('search.recentKeywords') }}</div>
            <div 
              v-for="(word, index) in getRecentKeywords()" 
              :key="index" 
              class="recent-word"
              @click="keywords = word; handleKeyup(); click_add_text = true"
            >
              {{ word }}              
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-brandme :show_modal="modal_how_it_works" :key="update_modal_how_it_works">
      <template #title> <h5 class="m-0">{{ $t('filters.howItWorks') }}</h5> </template>
      <template #body>
        <div v-for="(collapse, index) in collapses" :key="index" :class="{'border-bottom pb-2' : index < collapses.length - 1 }" class="pt-2 cursor-pointer" @click="collapse.model = !collapse.model">
          <div class="d-flex">
            <div>
              <feather-icon :icon="collapse.model ? 'ChevronDownIcon' : 'ChevronRightIcon'"></feather-icon> 
            </div>
            <div class="ml-1 mb-1">{{ collapse.title }}</div>
          </div>
          <b-collapse v-model="collapse.model" class="w-100 px-2 avenir-medium">
            <div v-html="collapse.description"></div>
          </b-collapse>
        </div>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BFormInput,
  BInputGroup,
  BCollapse,
} from 'bootstrap-vue';

export default {
  name: 'searchByCategory',
  components: {
    BFormInput,
    BInputGroup,
    BCollapse,
    ModalBrandme: () => import('@/views/components/modal/ModalBrandme.vue')
  },
  data() {
    return {
      keywords: this.keywords_dad,
      keywords_array: [],
      where: this.where_dad,
      show_keywords_added: false,
      vue_select: null,
      click_add_text: false,
      select_keywords: [
        {text: this.$t('search.anywhere'), value: 'anywhere'},
        {text: this.$t('search.content'), value: 'content'},
        {text: this.$t('search.description'), value: 'description'},
        {text: this.$t('search.exclude'), value: 'exclude'},
      ],
      selected_filter: this.no_categories ? {text: 'Palabras clave', value: 'keywords'} : {text: 'Categoría', value: 'category'},
      selected_keywords: {text: this.$t('search.anywhere'), value: 'anywhere'},
      show_category: false,
      preventHideFlag: false,
      collapses: [
        {
          model: false,
          title: '¿Qué solicitudes de búsqueda puedo realizar?',
          description: this.$t('filters.collpaseHelpSearch.requests')
        },
        {
          model: false,
          title: '¿Cómo puedo utilizar la búsqueda basada en palabras de antes?',
          description: this.$t('filters.collpaseHelpSearch.baseon')
        },
        {
          model: false,
          title: '¿Por qué no puedo organizar los resultados de búsqueda de otra forma?',
          description: this.$t('filters.collpaseHelpSearch.resultsSearch')
        }
      ],
      modal_how_it_works: false,
      update_modal_how_it_works: false,
    }
  },
  props: {
    keywords_dad: {
      type: String,
      default: ''
    },
    where_dad: {
      type: String,
      default: 'anywhere'
    },
    no_categories: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabled_custom_category: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    get_disabled_custom_category() {
      if (this.selected_filter.value === 'keywords') return false
      else return this.disabled_custom_category
    },
    get_arrays_grouped() {
      const grouped = this.categories.reduce((acc, word) => {
        const letter_initial = word.title[0].toUpperCase();
        if (!acc[letter_initial]) {
          acc[letter_initial] = [];
        }
        acc[letter_initial].push(word.title);
        return acc;
      }, {});
      return grouped;
    },
    select_filters() {
      if (this.no_categories) {
        return [{text: 'Palabras clave', value: 'keywords'}]
      } else return [
        {text: 'Categoría', value: 'category'},
        {text: 'Palabras clave', value: 'keywords'}
      ]
    },
    filtered_categories() {
      return this.categories.filter(i => i.title.toLowerCase().includes(this.keywords.toLowerCase()))
    },
  },
  async mounted() {
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    getRecentKeywords() {
      return localStorage.getItem('recent_keywords_searcher') ? JSON.parse(localStorage.getItem('recent_keywords_searcher')) : [];
    },
    addTag() {
      if (!this.keywords_array.find((i) => i.value === this.keywords) && this.keywords.length > 0) {
        this.keywords_array.push(this.getTagObject());
        this.saveTagLocalStorage()
        this.$refs.input_search_by_influencer.$el.focus();
        
        const new_models = [{name: 'keywords', value: this.keywords_array}];
        this.$emit('add_keyword_filter', new_models, this.keywords_array);
      }
    },
    saveTagLocalStorage() {
      const data = localStorage.getItem('recent_keywords_searcher');
      if (!data) localStorage.setItem('recent_keywords_searcher', JSON.stringify([this.keywords]))
      else if (data && !JSON.parse(data).find(i => i === this.keywords)) {
        const data_parse = JSON.parse(data);
        data_parse.unshift(this.keywords)
        localStorage.setItem('recent_keywords_searcher', JSON.stringify(data_parse))
      }

      this.keywords = '';
    },
    handleAddTagBegin() {
      this.click_add_text = true;
      this.keywords_array = []; 
      this.show_keywords_added = true;
      const new_models = [{name: 'keywords', value: this.keywords_array}];
      this.$emit('add_keyword_filter', new_models, this.keywords_array, {deleted_all: true});
      this.addTag()
    },
    deleteTag(tag) {
      this.keywords_array = this.keywords_array.filter(i => i.keyword !== tag.keyword)
      this.click_add_text = true;
      this.$refs.input_search_by_influencer.$el.focus();
      const new_models = [{name: 'keywords', value: this.keywords_array}];
      this.$emit('add_keyword_filter', new_models, this.keywords_array, {tag_deleted: tag.keyword});
    },
    getTagObject() {
      const type = this.selected_keywords.value !== 'exclude' ? 'include' : 'exclude';
      const where = this.selected_keywords.value === 'anywhere' || this.selected_keywords.value === 'exclude' ? ['description', 'content'] : [this.selected_keywords.value]
      return {type, where, keyword: this.keywords, type_keyword: this.selected_keywords.value}
    },
    handleKeyup() {
      if (this.selected_filter.value === 'keywords') {
        this.show_keywords_added = true;
        this.addTag();
      } 
    },
    addKeywordFilter() {
      const new_models = [{name: 'category', value: this.keywords}];
      this.$emit('add_keyword_filter', new_models, this.keywords, {category: true});
    },
    handleFocus() {
      if (this.selected_filter.value === 'category') this.show_category = true;
      else if (this.selected_filter.value === 'keywords') this.show_keywords_added = true;
    },
    handleBlur() {
      setTimeout(() => {        
        if (this.selected_filter.value === 'category') this.show_category = false;
        else if (!this.click_add_text) this.show_keywords_added = false;

        this.click_add_text = false;
      }, 200);
    },
    howItWorks() {
      this.modal_how_it_works = true;
      this.update_modal_how_it_works = !this.update_modal_how_it_works;
    }
  },
  watch: {
    keywords() {
      if (this.selected_filter.value === 'category') this.addKeywordFilter()
    },
    selected_keywords() {
      this.keywords = '';
    },
    selected_filter() {
      this.keywords = ''
      this.show_category = false;
      this.$refs.input_search_by_influencer.$el.blur();
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-white-custom {
  background-color: white;
}
.text-how-it-works {  
  display: flex;
  align-items: center;
  &:hover {
    color: #0096fe;
    cursor: pointer;
  }
}
.ml-05 {
  margin-left: 0.5em;
}
.card-filters-search-by {
  background-color: transparent;
  margin: 0.7em 0;
  border-radius: 0.42rem;
  height: auto;
  @media(min-width: 769px) {
    height: 41.4px;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    margin: 0.7em 0 1.7em;
    background-color: white;
  }
}
.select-filters-searcher-by {
  height: 100%;
  border-right: 1px solid #E2E8F0;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  background-color: white;

  @media(max-width: 768px) {
    width: 100%;
    margin-bottom: 0.7em;
    border-radius: 0.42rem;
  }
}
.input-search-by-influencer {
  @media(max-width: 768px) {
    border-radius: 0.42rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }
}
.float-suggestions-search {
  width: 100%;
  height: auto;
  max-height: 400px;
  overflow-x: scroll;
  background-color: white;
  border-radius: 6px;
  padding: 1.5em;
  position: absolute;
  top: 3.5em;
  z-index: 100;
  box-shadow: 0 20px 45px #181a1c29,0 -1px 6px #1d21260d;
  column-count: 1;
  column-fill: auto;
  .text-category {
    &:hover {
      background-color: #E2E8F0;
      transition: all 300ms;
      cursor: pointer;
      border-radius: 0.35em;
    }
  }
  @media(min-width: 768px) {
    column-count: 3;
  }
}
.p-07 {
  padding: 0.3em;
}
.float-suggestions-keywords {
  width: 100%;
  height: auto;
  max-height: 400px;
  background-color: white;
  border-radius: 6px;
  position: absolute;
  top: 3.5em;
  z-index: 100;
  box-shadow: 0 20px 45px #181a1c29,0 -1px 6px #1d21260d;
  overflow-y: scroll;
  
  .tag {
    background-color: #0096fe;
    width: fit-content;
    align-items: center;
    color: white;
    margin: 0.2em;
    padding: 0.2em 0.5em;
    border-radius: .7em;
  }
  .recent-word {
    font-family: 'avenir-medium';
    padding: 0.3em 0.7em;
    &:hover {
      background-color: #E2E8F0;
      cursor: pointer;
    }
  }
  .title-recent-word {
    padding: 0.7em;
  }
}
.p-05 {
  padding: 0.3em;
}
.m-05 {
  margin: 0.3em;
}
.m-06 {
  margin: 0.6em;
}
</style>
<style lang="scss">
#input-search-by-influencer {
  &.form-control:disabled, &.form-control[readonly] {
    background-color: white !important;
  }
}

.select-filters-searcher-by .vs__search::placeholder,
.select-filters-searcher-by .vs__dropdown-toggle
 {
  border: none;
  box-shadow: none;
  width: auto;
  height: 100%;
}
.select-filters-searcher-by .vs__search {
  opacity: 0;
}
.select-filters-searcher-by .vs__selected-options {
  flex-wrap: nowrap;
}
#select-filters-searcher-by-id .vs__selected  {  
  position: static;
  opacity: 1;
  white-space: nowrap;
}
</style>